import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import FilterListOutlined from '@material-ui/icons/FilterListOutlined';


const useStyles = theme => ({
  table: {
    margin: theme.spacing(0, 2, 0, 0)
  },
  checkId: {
    margin: theme.spacing(0, 2, 0, 0)
  },
  betweenCheck: {
    margin: theme.spacing(0, 0, 1, 0)
  }
});

class ViewChecksEmporter extends React.Component {
  constructor(props) {
    super(props);
    var today = this.parseToday();
    this.mounted = false;
    this.state = {
      openChecks: [],
      createdAt: today,
      isPaid: 3

    };
    this.parseToday = this.parseToday.bind(this);
    this.parseTime = this.parseTime.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.filterData(this.state.createdAt,this.state.isPaid);
    // this.unlisten = this.props.history.listen((location, action) => {
    //   this.getData();
    // });
  }


  filterData(createdAt, isPaid) {
    fetch(`https://cheztony.smartvision.dz/api/checksDateEmporter/${createdAt}/${isPaid}`)
      .then(res => res.json())
      .then(data => {
        if (this.mounted) {
          this.setState({ openChecks: data});
        }
      })
      .catch(() => console.error('server response error'));
  }
  
  handleChange(event) { 
     // date filter 
    this.setState({ [event.target.name]: event.target.value 
    }, function() {  		
      this.filterData(this.state.createdAt,this.state.isPaid) ; 
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    // this.unlisten();
  }

  parseToday() {
    const jsTime = new Date();

    let day =jsTime.getDate();
    let mm =jsTime.getMonth()+1;
    let yyy =jsTime.getFullYear();
    day = day <10 ? "0"+day : day;
    mm = mm <10 ? "0"+mm : mm;
    const timeString = `${yyy}-${mm}-${day}`;
    return  timeString;
  }

  parseTime(time) {
    const jsTime = new Date(time);
    let hours = jsTime.getHours()+1;
    let minutes = jsTime.getMinutes();
    const amPM = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = !hours ? 12 : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const timeString = `${hours}:${minutes} ${amPM}`;
    return timeString;
  }

  render() {
    const { classes } = this.props;
    const { url } = this.props;
    const noChecks = (
      <Grid item xs={12}>
        <Box m={4}>
          <Typography variant="h4" align="center">
          Pas de commandes  
          </Typography>
        </Box>
      </Grid>
    );

    const checks = this.state.openChecks.map(check => (
      <Grid item xs={12} key={check.checkId}>
        <Grid container className={classes.betweenCheck}>
          <Grid item xs={5}>
            <Box display="flex" justifyContent="flex-start" alignItems="center" flexWrap="wrap">
              <Box>
                <Typography variant="h5" noWrap className={classes.table}  color={check.isPaid === 'true'? 'primary' : 'secondary'} >
                  Emporté
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6">{this.parseTime(check.createdAt)}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box display="flex" justifyContent="flex-end" alignItems="center" flexWrap="wrap">
              <Box>
                <Typography align="right" variant="h6" noWrap className={classes.checkId}>
                  Commande #{check.code_com}
                </Typography>
              </Box>
              <Box>
                <Link to={`${url}/checkitem/${check.checkId}/${check.tableId}`}>
                  <Button
                    variant="contained"
                    size="medium"
                    className={classes.checkId}
                    startIcon={<ReceiptIcon />}
                    style={{
                      backgroundColor: '#1DBE94',
                      color: 'white',
                      display:'inline-block'
                    }}
                  >
                     Commande
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Divider />
      </Grid>
    ));
// 
    return (
      <Box mt={3} ml={2} mb={2}>
        <Grid >
          <TextField
            onChange={this.handleChange}
            value={this.state.createdAt}
            label="Date"
            margin="normal"
            id="createdAt"
            name="createdAt"
            variant="outlined"
            format="MM/dd/yyyy"
            InputLabelProps={{ shrink: true }}
            type="date"
          /> &nbsp; &nbsp;
          <TextField
              onChange={this.handleChange}
              margin="normal"
              id="isPaid"
              name="isPaid"
              defaultValue='3'
              label="Etat de commande"
              variant="outlined"
              select
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterListOutlined />
                  </InputAdornment>
                )
              }}>

              <MenuItem value="1">
                <em>Tous</em>
              </MenuItem>
              <MenuItem value="2">
                <em>Payée</em>
              </MenuItem>
              <MenuItem value="3">
                <em>En Cours</em>
              </MenuItem>
            </TextField>
        </Grid>
        <Grid container spacing={1}>
          {checks.length > 0 ? checks : noChecks}
        </Grid>
      </Box>
    );
  }
}

export default withStyles(useStyles)(ViewChecksEmporter);
