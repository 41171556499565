import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';


const useStyles = theme => ({
  nav: {
    // backgroundColor: '#3c52b2',
    color: '#fff',
    display: 'inline-block',
    // textDecoration: 'none',
    '&:hover': {
      color: '#858582'
    },
    cursor: 'pointer',
    minHeight: 60,
    lineHeight: 1.3,
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  padding: {
    padding: 0
  },
  navbar: {
    background: '#1dbe94'
  },
  align: {
    textAlign: 'center'
  },
});

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      methods: [], 
      anchorEl: null,
      open: false,
      userId: undefined,
      ITEM_HEIGHT: 48
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClose1 = this.handleClose1.bind(this);
    this.getMethods = this.getMethods.bind(this);
  }
  handleClick(event) {
    this.setState({anchorEl: event.currentTarget, open: Boolean(event.currentTarget), id: "simple-popover"});
  }

  handleClose(event) {
    localStorage.clear();
    window.location.href = '/';
  }

  handleClose1(event) {
    this.setState({anchorEl: event.currentTarget, open: false, id: undefined});
  }

  getMethods(id) {

    fetch(`https://cheztony.smartvision.dz/api/methods/${id}`)
      .then(response => response.json())
      .then(data => {
        console.log(data[0].ischild);
        this.setState({
          methods: data
        });
      })
      .catch(error => {
        console.error(error);
      });
  }
  componentDidMount() {
    this.getMethods(localStorage.getItem('id'));
  }

  render() {
    const { classes } = this.props;
    // const userId = localStorage.getItem('id');
    const options = [
      'Déconnexion',
    ];
    // console.log(this.state.methods,'sdjgg');
    const methods = this.state.methods.map(method => {
      console.log(method.ischild);
      if (method.ischild === '2') {
        return (
        <Grid item xs={4} sm>
          <Link to={method.method_route}>
            <Button size="large" color="inherit" className={classes.nav}>
              {method.method_name}
            </Button>
          </Link>
        </Grid>
      );
    }else {
      return(
      <></>
      );
    }
      
    });
    return (
      <AppBar position="sticky" className={classes.navbar}>
        <Toolbar className={classes.padding}>
          <Grid container spacing={1} className={classes.align}>
            {methods}
            <div>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={this.handleClick}
                aria-describedby={this.id}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={this.id}
                anchorEl={this.state.anchorEl}
                keepMounted
                open={this.state.open}
                onClose={this.handleClose1}
                PaperProps={{
                  style: {
                    maxHeight: this.state.ITEM_HEIGHT * 4.5,
                    width: '20ch',
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem key={option} onClick={option !== 'Déconnexion' ? this.handleClose1 : this.handleClose}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(useStyles)(NavBar);
