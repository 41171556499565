import React from 'react';
import Container from '@material-ui/core/Container';
import {
  HashRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import NavBar from './NavBar';
import ViewRestaurant from './ViewRestaurant';
import ViewChef from './ViewChef';
import WaitList from './WaitList';
import MenuCustomizer from './MenuCustomizer';
import SalesReport from './SalesReport';
import ViewNewOrder from './ViewNewOrder';
import Login from './Login';
import CatCustomizer from './CatCustomizer';
import AccountManagement from './AccountManagement';
import AddUser from './AddUser';
import UpdateUser from './UpdateUser';
import Credentials from './Credentials';
import ViewNewOrderEmporter from './ViewNewOrderEmporter';
import ViewEmporter from './ViewEmporter';
import Stock from './Stock';
import Page404 from './Page404';
const cars = {'ViewRestaurant':{'component':ViewRestaurant,'component_name':'ViewRestaurant','ischild':null,'path':'/'},
            'ViewChef':{'component':ViewChef,'component_name':'ViewChef','ischild':null,'path':'/chef'},
            'ViewEmporter':{'component':ViewEmporter,'component_name':'ViewEmporter','ischild':null,'path':'/'},
            'WaitList':{'component':WaitList,'component_name':'WaitList','ischild':null,'path':'/wait-list'},
            'MenuCustomizer':{'component':MenuCustomizer,'component_name':'MenuCustomizer','ischild':null,'path':'/menu-customizer'},
            'CatCustomizer':{'component':CatCustomizer,'component_name':'CatCustomizer','ischild':null,'path':'/cat-customizer'},
            'Stock':{'component':Stock,'component_name':'Stock','ischild':null,'path':'/stock'},
            'SalesReport':{'component':SalesReport,'component_name':'SalesReport','ischild':null,'path':'/sales-report'},
            'AccountManagement':{'component':AccountManagement,'component_name':'AccountManagement','ischild':null,'path':'/account-management'}
            };
const tr = [{'component':ViewRestaurant,'component_name':'ViewRestaurant','ischild':null,'path':'/'},
            {'component':ViewChef,'component_name':'ViewChef','ischild':null,'path':'/chef'},
            {'component':ViewEmporter,'component_name':'ViewEmporter','ischild':null,'path':'/'},
            {'component':WaitList,'component_name':'WaitList','ischild':null,'path':'/wait-list'},
            {'component':MenuCustomizer,'component_name':'MenuCustomizer','ischild':null,'path':'/menu-customizer'},
            {'component':CatCustomizer,'component_name':'CatCustomizer','ischild':null,'path':'/cat-customizer'},
            {'component':Stock,'component_name':'Stock','ischild':null,'path':'/stock'},
            {'component':SalesReport,'component_name':'SalesReport','ischild':null,'path':'/sales-report'},
            {'component':AccountManagement,'component_name':'AccountManagement','ischild':null,'path':'/account-management'}
            ];
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      isLoading: true,
      status: false,
      methods: [],
      accessM: [],
      userId: null
    };
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.getMethods = this.getMethods.bind(this);
  }


  getMethods(id) {
    fetch(`https://cheztony.smartvision.dz/api/methods/${id}`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          methods: data
        });
        const access = []
        for (let i=0; i < data.length; i++) {
             access.push(data[i].component);
          } 
          this.setState({
            accessM: access
          });
        console.log('methods: ',access);
      })
      .catch(error => {
        console.error(error);
      });
  }
  onChangeStatus(s, id) {
    this.setState({status: s});
    this.setState({userId: id});
    this.state.status = localStorage.setItem('status', s);
    this.state.userId = localStorage.setItem('id', id);
  }
  componentDidMount() {
    const status = localStorage.getItem('status') === 'true';
    const userId = localStorage.getItem('id');
    this.getMethods(userId);
    this.setState({ status });
    if (status === 'true') {
      this.getMethods(localStorage.getItem('id'));
    };
    // Store the data with time
    const EXPIRE_TIME = 1000*60*60*24;
    // localStorage.setItem('storedData', JSON.stringify({
    //   time: new Date(),
    //   status: "true",
    //   id: userId
    // }));
    // start the time out

    setTimeout(function() {
        localStorage.removeItem('status');
        localStorage.removeItem('id');
    }, EXPIRE_TIME); 
      // this.onChangeStatus(this.state.status);
  }

  render() {
    if(this.state.status && this.state.userId !== undefined){
    
      return(
        <Router>
          <Container disableGutters={true}>
            <NavBar />
            <Switch>
              <Route path={cars.ViewChef.path}>
              <cars.ViewChef.component />
              </Route>
              <Route path={cars.WaitList.path}>
                <cars.WaitList.component />
              </Route>
              <Route path="/menu-customizer">
                <MenuCustomizer />
              </Route>
              <Route
                path="/order/:tableId"
                component={ViewNewOrder}
                // history={history}
              />
              <Route
                path="/credentials/:userId"
                component={Credentials}
                // history={this.props.history}
              />
              <Route
                path="/update-user/:userId"
                component={UpdateUser}
                // history={this.props.history}
              />
              <Route path="/sales-report">
                <SalesReport />
              </Route>
              <Route path="/cat-customizer">
                <CatCustomizer />
              </Route>
              <Route path="/account-management">
                <AccountManagement />
              </Route>
              <Route path="/stock">
                <Stock />
              </Route>
              <Route path="/add-user">
                <AddUser />
              </Route>
              <Route path="/error">
                  <Page404 />
              </Route>



                            // Add by Amina 
              <Route
                path="/orderEmporter/"
                component={ViewNewOrderEmporter}
                // history={history}
              />
              <Route path="/view-emporter" component={ViewEmporter} />
              <Route strict path="/" component={ViewRestaurant} type="private"/>
            </Switch>
          </Container>
        </Router>
        );

    } else {
    return (
        <Container disableGutters={true}>
          <Login onChangeStatus={this.onChangeStatus} />
        </Container>
  
      ); 
    }

  }
}
