import React from 'react';
import Grid from '@material-ui/core/Grid';
import MenuCard from './MenuCard';

export default class MenuList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      categoryId: 0
    };
    this.addItem = this.addItem.bind(this);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const next = nextProps.categoryId;
    if (nextProps.categoryId !== 0) {
      return {
        categoryId: next,
      };
    }
    return true;
  }

  addItem(item) {
    this.props.addToOrder(item);
  }

  componentDidMount() {
    // console.log('categoryId from menu list : '+this.state.categoryId);
    fetch('https://cheztony.smartvision.dz/api/menus')
      .then(res => res.json())
      .then(data => this.setState({ menus: data }))
      .catch(() => console.error('server response error'));
  }
  componentDidUpdate(prevProps, prevState){
    if (prevProps.categoryId !== this.props.categoryId) {
      if(this.props.categoryId != 0)
        fetch(`https://cheztony.smartvision.dz/api/menus/${this.props.categoryId}`)
        .then(res => res.json())
        .then(data => this.setState({ menus: data }))
        .catch(() => console.error('server response error'));
      else
        fetch('https://cheztony.smartvision.dz/api/menus')
        .then(res => res.json())
        .then(data => this.setState({ menus: data }))
        .catch(() => console.error('server response error'));
    }
    
  }

  render() {
     // console.log(this.props.categoryId);
     // console.log('categoryId from parent of menu list :'+this.props.categoryId);
      const menuCard = this.state.menus.map(menu => (
        <Grid item xs={6} sm={4} key={menu.itemId}>
          <MenuCard menuItem={menu} addItem={this.addItem}/>
        </Grid>
      ));

      return (
        <Grid container spacing={3}>
          {menuCard}
        </Grid>
      );
    }
  }
