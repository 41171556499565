import React from 'react';
import Grid from '@material-ui/core/Grid';
import CatCustomizerTable from './CatCustomizerTable';
import CatCustomizerForm from './CatCustomizerForm';
import Page404 from './Page404';


export default class CatCustomizer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      formStatusEdit: false,
      formEditItem: {},
      accessM: []
    };
    this.reloadMenus = this.reloadMenus.bind(this);
    this.editItem = this.editItem.bind(this);
    this.clearEditItem = this.clearEditItem.bind(this);
  }

  componentDidMount() {
    this.getMethods(localStorage.getItem('id'));
    this.getMenus();
  }

  getMethods(id) {
    fetch(`https://cheztony.smartvision.dz/api/methods/${id}`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          methods: data
        });
        let access = []
        for (let i=0; i < data.length; i++) {
             access.push(data[i].component);
          } 
          this.setState({
            accessM: access
          });
      })
      .catch(error => {
        console.error(error);
      });
  }

  getMenus() {
    fetch('https://cheztony.smartvision.dz/api/category')
      .then(response => response.json())
      .then(data => {
        this.setState({
          menus: data,
          formStatusEdit: false,
          formEditItem: {}
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  reloadMenus() {
    this.getMenus();
  }

  editItem(categoryId) {
    for (const namecat of this.state.menus) {
      if (namecat.categoryId === parseInt(categoryId)) {
        const editItem = { ...namecat };
        this.setState({
          formStatusEdit: true,
          formEditItem: editItem
        });
        break;
      }
    }
  }

  clearEditItem() {
    this.setState({ formStatusEdit: false, formEditItem: {} });
  }

  render() {

      return (
        <>
        <Grid container spacing={2}>
        {this.state.accessM.includes('CatCustomizer') ? 
        <>
        <Grid item xs={12} md={8}>
          <CatCustomizerTable
            menuList={this.state.menus}
            reloadMenus={this.reloadMenus}
            editItem={this.editItem}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CatCustomizerForm
            reloadMenus={this.reloadMenus}
            editView={this.state.formStatusEdit}
            editItem={this.state.formEditItem}
            resetItem={this.clearEditItem}
          />
        </Grid>
        </>
      :
      <>
        <Page404 />
        </>
      }
      </Grid>
      </>
    );
}}
