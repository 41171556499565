import React from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import FastfoodOutlined from '@material-ui/icons/FastfoodOutlined';
import HeightOutlined from '@material-ui/icons/HeightOutlined';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
  title: {
    padding: theme.spacing(2)
  },
  form: {
    padding: theme.spacing(2, 2),
    margin: theme.spacing(0)
  },
  upload: {
    display: 'none'
  },
  uploadBtn: {
    padding: theme.spacing(1.5)
  },
  button: {
    minWidth: 100,
    padding: theme.spacing(1),
    margin: theme.spacing(1, 2)
  },
  imageName: {
    padding: theme.spacing(1),
    fontStyle: 'italic',
    color: 'gray'
  }
});


class MenuCustomizerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editView: false,
      item: '',
      item2: '',
      cost: '',
      salePrice: '',
      categoryId: '',
      image: '',
      categories: [],
      size: ''
    };
    this.reloadCategories = this.reloadCategories.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.handleChangeSize = this.handleChangeSize.bind(this);

  }

  // add by Rania 25/08/2021

  componentDidMount() {
    this.getCategory();
  }

  getCategory() {
    fetch('https://cheztony.smartvision.dz/api/category')
      .then(response => response.json())
      .then(data => {
        this.setState({
          categories: data,
          formStatusEdit: false,
          formEditItem: {}
        });
      })
      .catch(error => {
        console.error(error);
      });
  }
  reloadCategories() {
    this.getCategory();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.editView && nextProps.editItem.item !== prevState.item) {
      const menu = nextProps.editItem;
      return {
        editView: true,
        item: menu.item,
        item2: menu.item,
        cost: menu.cost,
        salePrice: menu.salePrice,
        categoryId: menu.categoryId,
        namecat: menu.categoryId,
        image: menu.imageUrl,
        size: menu.size
      };
    }
    return true;
  }

  handleReset() {
    this.setState({
      editView: false,
      item: '',
      item2: '',
      cost: '',
      salePrice: '',
      namecat: '',
      categoryId: '',
      image: '',
      size: ''
    });
    this.props.resetItem();
  }

  handleSubmit(event) {
    event.preventDefault();
    this.sendItem(event.target, this.props.editView);
    this.handleReset();
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleChangeCategory(e) {
     this.setState({ [e.target.name]: e.target.value });
    }

   handleChangeSize(e) {
    this.setState({ [e.target.name]: e.target.value });
    }

  sendItem(event, editView) {
    let url = '';
    let reqType = '';

    if (editView) {
      url = `https://cheztony.smartvision.dz/api/menus/${this.props.editItem.itemId}`;
      reqType = 'PUT';
    } else {
      url = 'https://cheztony.smartvision.dz/api/menus';
      reqType = 'POST';
    }

    const formData = new FormData();
    formData.append('item', this.state.item2);
    formData.append('cost', parseFloat(this.state.cost).toFixed(2));
    formData.append('salePrice', parseFloat(this.state.salePrice).toFixed(2));
    formData.append('categoryId',this.state.categoryId);
    formData.append('size',this.state.size);
    if (this.state.image) {

      formData.append('image', event.image.files[0]);
    } else {
      formData.append('image', null);
    }

    fetch(url, {
      method: reqType,
      body: formData
    })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          this.props.reloadMenus();
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    const { classes } = this.props;
    const titleText = this.props.editView ? 'Modifier le menu ' : 'Ajouter un nouveau menu ';
    const buttonText = this.props.editView ? 'Modifier' : 'Ajouter';
    const catList = this.state.categories.map((option) => (<MenuItem value={option.categoryId}><em>{option.namecat}</em></MenuItem>));

    return (
      <Box mt={2}>
        <Typography variant="h4" align="center" className={classes.title}>
          {titleText}
        </Typography>
        <form
          onSubmit={this.handleSubmit}
          onReset={this.handleReset}
          name="menuForm"
        >
          <FormControl fullWidth className={classes.form}>
            <TextField
              onChange={this.handleChange}
              value={this.state.item2}
              margin="normal"
              id="item2"
              label="Nom"
              variant="outlined"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RestaurantMenuIcon />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              onChange={this.handleChange}
              value={this.state.cost}
              required
              margin="normal"
              id="cost"
              label="Cout"
              variant="outlined"
              type="number"
              InputProps={{
                startAdornment: (
                  <strong> DZ </strong>
                )
              }}

            />
            <TextField
              onChange={this.handleChange}
              value={this.state.salePrice}
              required
              margin="normal"
              id="salePrice"
              label="Prix de vente"
              variant="outlined"
              type="number"
              InputProps={{
                startAdornment: (
                  <strong> DZ </strong>
                )
              }}
            />
            <TextField
              onChange={this.handleChangeSize}
              value={this.state.size}
              margin="normal"
              name="size"
              label="Taille"
              variant="outlined"
              select
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HeightOutlined />
                  </InputAdornment>
                )
              }}>

              <MenuItem value="M">
                <em>M</em>
              </MenuItem>
              <MenuItem value="L">
                <em>L</em>
              </MenuItem>
              <MenuItem value="XL">
                <em>XL</em>
              </MenuItem>
              <MenuItem value="XXL">
                <em>XXL</em>
              </MenuItem>
            </TextField>
            <TextField
              onChange={this.handleChangeSize}
              value={this.state.categoryId}
              required
              margin="normal"
              name="categoryId"
              label="Catégorie"
              variant="outlined"
              select
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FastfoodOutlined />
                  </InputAdornment>
                )
              }}>
              {catList}
            </TextField>
            <Box mt={1}>
              <input
                accept="image/*"
                className={classes.upload}
                id="image"
                name="image"
                type="file"
                onChange={this.handleChange}
              />
              <label htmlFor="image">
                <Button
                  variant="outlined"
                  color="default"
                  component="span"
                  size="large"
                  fullWidth
                  className={classes.uploadBtn}
                >
                  Téléchargement d'images de menu 
                </Button>
                <Typography
                  variant="caption"
                  display="block"
                  className={classes.imageName}
                >
                  {this.state.image}
                </Typography>
              </label>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              mt={5}
              p={1}
              bgcolor="background.paper"
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                {buttonText}
              </Button>
              <Button
                type="reset"
                variant="contained"
                color="secondary"
                className={classes.button}
              >
                Annuler
              </Button>
            </Box>
          </FormControl>
        </form>
      </Box>
    );
  }
}

export default withStyles(useStyles)(MenuCustomizerForm);