import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

export default function OrderBillTotalEmporter(props) {
  const result = (
    <>
      <TableRow>
        <TableCell rowSpan={3} />
        <TableCell>Total </TableCell>
        <TableCell align="right">{props.price.Total}  <strong>DA</strong></TableCell>
      </TableRow>
     
    </>
  );
  return props.price.Total === '0.00' ? <></> : result;
}
