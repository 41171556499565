import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Page404 from './Page404';

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  detail:{
    display: 'block',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '15%',
    margin: theme.spacing(3, 0, 2),
    alignSelf: 'center',
  },
  uploadBtn: {
     padding: theme.spacing(1.5)
  },
  upload: {
    display: 'none'
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
var data1 = [];
class Credentials extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        methodId:'',
        userId: 1,
        methods: [],
        choices: [],
        accessM: [],
        accessMethods: [],
        popup:false,
        isChecked: false,
        isLoading: false

      };
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleOpen = this.handleOpen.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleCredentials = this.handleCredentials.bind(this);
      this.handleFieldChange = this.handleFieldChange.bind(this);
      this.getMethods = this.getMethods.bind(this);
    }

  getMethodsDisplay(id) {
      fetch(`https://cheztony.smartvision.dz/api/methods/${id}`)
        .then(response => response.json())
        .then(data => {
          // this.setState({
          //   methods: data
          // });
          let access = []
          for (let i=0; i < data.length; i++) {
               access.push(data[i].component);
            } 
            this.setState({
              accessMethods: access
            });
        })
        .catch(error => {
          console.error(error);
        });
    }

   getMethods() {
      fetch('https://cheztony.smartvision.dz/api/methods')
        .then(response => response.json())
        .then(data => {
          console.log(data, 'all methods');
          this.setState({
            methods: data
          });
        })
        .catch(error => {
          console.error(error);
        });
    }
   componentDidMount() {
      this.getMethods();
      this.getAccess(this.props.match.params.userId);
      this.getChecked();
      this.getMethodsDisplay(localStorage.getItem('id'));
    }
    
  getAccess(id) {
      fetch(`https://cheztony.smartvision.dz/api/access/${id}`)
        .then(response => response.json())
        .then(data => {
          let access = [];
          for (let i=0; i < data.length; i++) {
             access.push(data[i].methodId);
          } 
          this.setState({
            accessM: access
          });
          this.setState({isLoading: true});
          data1 = access;
          console.log(data1, 'kgj');
        })
        .catch(error => {
          console.error(error);
        });
    }
       
  handleSubmit(event) {
      event.preventDefault();
      this.add(event.target);
    }

  getChecked(){
    let arr = [];
    for (let i = 0; i < this.state.methods.length; i++) {
      arr.push(this.state.methods[i].methodId);
    }
      for (let i = 0; i < arr; i++) {
        if (this.state.accessM.includes(arr[i])) {
          this.setState({isChecked: true});

        } else {
          this.setState({isChecked: false});

        }
      }
  }

  handleFieldChange(event){
      
    // const userId = parseInt(this.props.match.params.userId);
    var ev = parseInt(event.target.value);
    if (this.state.accessM.find(e=>(e===ev))===ev) {

      const index = this.state.accessM.indexOf(parseInt(event.target.value));
      if (index > -1) {
         this.state.accessM.splice(index, 1); 
      };
    }else{
      this.state.accessM.push(parseInt(event.target.value));
    }
    // this.setState({
    //         accessM: data1
    //       });
    // console.log(this.state.accessM, 'handleFieldChange',data1, 'fds');
    };

    handleOpen(){
      this.setState({popup: true});
    };

    handleClose(){
      this.setState({popup: false});
    };
   handleCredentials() {
    const userId = parseInt(this.props.match.params.userId);
    const orders = data1;
    const orderItems = { userId: userId, items: orders };

    fetch('https://cheztony.smartvision.dz/api/access', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(orderItems)
    }).then(response => {
      if (response.status === 201) {
       // window.location.href = "/account-management";
      this.props.history.push("/account-management");
      this.setState({popup: false});
      }
    }).catch(error => console.error(error.message));
  }
    // ********************

    render() {
      const { classes } = this.props;
      if (this.state.accessMethods.includes("Credentials")) {
        if (this.state.isLoading === true) {
                  const orderBtnComponent = (
                  <>
                    <Button
                        onClick={this.handleOpen}
                        align="center"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        Valider
                      </Button>
                      <Dialog open={this.state.popup} TransitionComponent={Transition} keepMounted onClose={this.handleClose}>
                        <DialogTitle>New</DialogTitle>
                        <DialogContent>
                          <DialogContentText>Your order has been successfully processed.</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={this.handleCredentials}
                            color="primary"
                            variant="contained"
                            size="large"
                          >
                            OKAY
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  );

                    // const userId = parseInt(this.props.match.params.userId);
                    console.log(this.state.methods, 'methods');
                    const methodList = this.state.methods.map((option) => (
                      <FormControlLabel 
                            control={
                              <Checkbox 
                                key={option.methodId}
                                onChange={this.handleFieldChange}
                                name={option.methodId}
                                color="primary"
                                value={option.methodId}
                                defaultChecked={this.state.accessM.includes(option.methodId)}
                                // checked={data1.includes(option.methodId)}
                              />
                            }
                            label={option.method_name}
                          />

                      ));
                    const titleText = 'Privilèges';

                  return (
                    <Grid item xs={12} md={12}>
                      <CssBaseline />
                      <div className={classes.paper}>
                        <Typography variant="h4" align="center" className={classes.title}>
                        {titleText}
                        </Typography>
                        <form className={classes.form} noValidate>
                          <FormControl fullWidth className={classes.form}>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography> Cliquez ici </Typography>
                              </AccordionSummary>
                              <AccordionDetails className={classes.detail}>
                                {methodList}
                              </AccordionDetails>
                            </Accordion>
                            {orderBtnComponent}
                          </FormControl>
                        </form>
                       </div>        
                    </Grid>
                  );
        } else {
           return (
            <Grid item xs={6} sm>
              <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                
              </div>
            </Container>         
            </Grid>
            );
        }
      } else {
        return(
          <>
          <Page404 />
          </>
        );
      }     
  }
}

export default withStyles(useStyles)(Credentials);


// 