import { createTheme  } from '@material-ui/core/styles';

const theme = createTheme ({
  palette: {
    primary: {
      main: '#1dbe94',
      contrastText: '#fff'
    },
    secondary: {
      main: '#ef476f'
    },
    bl: {
	 main: '#3498db',
	 contrastText: '#fff'
    }
  }
});

export default theme;
