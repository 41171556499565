import React from 'react';
import Grid from '@material-ui/core/Grid';
import MenuCardEmporter from './MenuCardEmporter';

export default class MenuListEmporter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [], 
      categoryId: 0
    };
    this.addItem = this.addItem.bind(this);
  }

  // Add By Rania
  static getDerivedStateFromProps(nextProps, prevState) {
    const next = nextProps.categoryId;
    if (nextProps.categoryId !== 0) {
      return {
        categoryId: next,
      };
    }
    return true;
  }
  // END

  addItem(item) {
    this.props.addToOrder(item);
  }

  componentDidMount() {
    fetch('https://cheztony.smartvision.dz/api/menus')
      .then(res => res.json())
      .then(data => this.setState({ menus: data }))
      .catch(() => console.error('server response error'));
  }

  // Add By Rania
  componentDidUpdate(prevProps, prevState){
    if (prevProps.categoryId !== this.props.categoryId) {
      if(this.props.categoryId != 0)
        fetch(`https://cheztony.smartvision.dz/api/menus/${this.props.categoryId}`)
        .then(res => res.json())
        .then(data => this.setState({ menus: data }))
        .catch(() => console.error('server response error'));
      else
        fetch('https://cheztony.smartvision.dz/api/menus')
        .then(res => res.json())
        .then(data => this.setState({ menus: data }))
        .catch(() => console.error('server response error'));
    }
    
  }
  // END
  render() {
    const menuCard = this.state.menus.map(menu => (
      <Grid item xs={6} sm={4} key={menu.itemId}>
        <MenuCardEmporter menuItem={menu} addItem={this.addItem}/>
      </Grid>
    ));

    return (
      <Grid container spacing={3}>
        {menuCard}
      </Grid>
    );
  }
}
