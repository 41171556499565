import React from 'react';
import ViewPay from './ViewPay';
import FloorPlan from './FloorPlan';
import ViewChecks from './ViewChecks';
import ViewCheckItem from './ViewCheckItem';
import Grid from '@material-ui/core/Grid';
import { Switch, Route } from 'react-router-dom';
import RestaurantPrint from './RestaurantPrint';

export default class ViewRestaurant extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      viewRestaurant: [],
      tableData: {}
    };
  } 

  componentDidMount() {
    this.tablePaid();
  }

  tablePaid() {
    fetch('https://cheztony.smartvision.dz/api/paid')
      .then(response => response.json())
      .then(data => {
        this.setState({ viewRestaurant: data });
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    
    let { path, url } = this.props.match;
    const { history } = this.props;
    path = path.replace(/\/$/, '');
    url = url.replace(/\/$/, '');
    
    const { viewRestaurant } = this.state;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <ViewChecks 
              url={url} 
              history={history}
              tableData={viewRestaurant}
              />
          </Grid>
         
          <Grid
            item
            xs={12}
            md={8}
            style={{
              paddingTop: '4px',
              paddingRight: '4px',
              paddingLeft: '0px'
            }}
          >
            <Switch>
              <Route path={`${path}/paycheck/:checkId/:tableId`} component={ViewPay} />
              <Route path={`${path}/checkitem/:checkId/:tableId`} component={ViewCheckItem} />
              <Route exact path={path} component={FloorPlan} />
            </Switch>
            <Switch>
              <Route path={`${path}/paycheck/:checkId/:tableId`} component={RestaurantPrint} />
            </Switch>
          </Grid>
        </Grid>
      </>
    );
  }
}
