import React from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
  title: {
    padding: theme.spacing(2)
  },
  form: {
    padding: theme.spacing(0, 2),
    margin: theme.spacing(0)
  },
  upload: {
    display: 'none'
  },
  uploadBtn: {
    padding: theme.spacing(1.5)
  },
  button: {
    minWidth: 100,
    padding: theme.spacing(1),
    margin: theme.spacing(1, 2)
  },
  imageName: {
    padding: theme.spacing(1),
    fontStyle: 'italic',
    color: 'gray'
  }
});


class CatCustomizerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editView: false,
      namecat: '',
      rania: ''
    };
    this.reloadCategories = this.reloadCategories.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  // add by Rania 25/08/2021


  componentDidMount() {
    this.getCategory();
  }

   getCategory() {
    fetch('https://cheztony.smartvision.dz/api/category')
      .then(response => response.json())
      .then(data => {
        this.setState({
          categories: data,
          formStatusEdit: false,
          formEditItem: {}
        });
      })
      .catch(error => {
        console.error(error);
      });
  }
  reloadCategories() {
    this.getCategory();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.editView && nextProps.editItem.namecat !== prevState.namecat) {
      const menu = nextProps.editItem;
      return {
        editView: true,
        namecat: menu.namecat,
        rania: menu.namecat
      };
    }
    return true;
  }

  handleReset() {
    this.setState({
      editView: false,
      namecat: '',
      rania: ''
    });
    this.props.resetItem();
  }

  handleSubmit(event) {
    event.preventDefault();
    this.sendItem(event.target, this.props.editView);
    this.handleReset();
  }
  handleChange(event) {
    this.setState({[event.target.id]: event.target.value});
  }
  // handleChange(event) {
  //   console.log([event.target.id],this.state.namecat,event.target.value);
  //   this.setState({ namecat: event.target.value });
  // }

  sendItem(event, editView) {
    let url = '';
    let reqType = {};

    if (editView) {
      url = `https://cheztony.smartvision.dz/api/category/${this.props.editItem.categoryId}`;
      reqType = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ namecat: this.state.rania })
      };
    } else {
      url = 'https://cheztony.smartvision.dz/api/category';
      reqType = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ namecat: this.state.rania })
      };
    }

    const formData = new FormData();
    formData.append('namecat', this.state.namecat);

    fetch(url, reqType)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          this.props.reloadMenus();
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    const { classes } = this.props;
    const titleText = this.props.editView ? 'Modifier Catégorie ' : 'Ajouter une Catégorie ';
    const buttonText = this.props.editView ? 'Modifier' : 'Ajouter';
    
    return (
      <Box mt={2}>
        <Typography variant="h4" align="center" className={classes.title}>
          {titleText}
        </Typography>
        <form
          onSubmit={this.handleSubmit}
          onReset={this.handleReset}
          name="menuForm"
        >
          <FormControl fullWidth className={classes.form}>
            <TextField
              onChange={this.handleChange}
              value={this.state.rania}
              margin="normal"
              id="rania"
              label="Nom"
              variant="outlined"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RestaurantMenuIcon />
                  </InputAdornment>
                )
              }}
            />
            
            <Box
              display="flex"
              justifyContent="center"
              mt={5}
              p={1}
              bgcolor="background.paper"
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                {buttonText}
              </Button>
              <Button
                type="reset"
                variant="contained"
                color="secondary"
                className={classes.button}
              >
                Annuler
              </Button>
            </Box>
          </FormControl>
        </form>
      </Box>
    );
  }
}

export default withStyles(useStyles)(CatCustomizerForm);