import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Page404 from './Page404';

const useStyles = theme => ({
  image: {
    width: theme.spacing(12),
    height: theme.spacing(8)
  },
  cell: {
    padding: theme.spacing(1.3)
  }
});

class SaleReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = { saleData: [], totalSold: 0, totalProfit: 0, accessM: [] };
    this.getMethods = this.getMethods.bind(this);

  }
  getMethods(id) {
    fetch(`https://cheztony.smartvision.dz/api/methods/${id}`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          methods: data
        });
        let access = []
        for (let i=0; i < data.length; i++) {
             access.push(data[i].component);
          } 
          this.setState({
            accessM: access
          });
        console.log('methods: ',this.state.accessM);
      })
      .catch(error => {
        console.error(error);
      });
  }

  getTotal(data) {
    let totalSold = 0;
    let totalProfit = 0;

    for (const item in data) {
      totalSold += parseInt(data[item]['TotalSold']);
      totalProfit += parseFloat(data[item].Profit);
    }
    return { totalSold, totalProfit };
  }

  componentDidMount() {
    fetch('https://cheztony.smartvision.dz/api/sales')
      .then(response => response.json())
      .then(data => {
        const total = this.getTotal(data);
        this.setState({
          saleData: data,
          totalSold: total.totalSold,
          totalProfit: total.totalProfit.toFixed(2)
        });
      })
      .catch(error => {
        console.error(error);
      });
      this.getMethods(localStorage.getItem('id'));
  }

  render() {
    const { classes } = this.props;

    const noReport = (
      <TableRow>
        <TableCell align="center" colSpan={7}>
          <Typography variant="h5">Il n'y a pas encore de rapports.</Typography>
        </TableCell>
      </TableRow>
    );

    const salesRow = this.state.saleData.map((item, index) => {
      return (
        <TableRow key={item['Item Name']}>
          <TableCell align="center">{index + 1}</TableCell>
          <TableCell align="center">{item['ItemName']}</TableCell>
          <TableCell align="center" className={classes.cell}>
            <Box display="flex" justifyContent="center" bgcolor="background.paper">
              <Avatar
                variant="rounded"
                alt={item['Item Name']}
                className={classes.image}
                src={'https://cheztony.smartvision.dz/'+item.Image}
              />
            </Box>
          </TableCell>
          <TableCell align="center">{item['TotalSold']}</TableCell>
          <TableCell align="center">{item['SalePrice']} DA</TableCell>
          <TableCell align="center">{item.Cost} DA</TableCell>
          <TableCell align="center">
            {parseFloat(item.Profit).toFixed(2)} DA
          </TableCell>
        </TableRow>
      );
    });

    const salesTotal = (
      <>
        <TableRow >
          <TableCell rowSpan={2} />
          <TableCell rowSpan={2} />
          <TableCell colSpan={4}>
            <Typography variant="h5" color="secondary">
              Total des Ventes
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h5" color="primary">
              {this.state.totalSold}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={4}>
            <Typography variant="h5" color="secondary">
               Le Profit Total
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h5" color="primary">
              {parseFloat(this.state.totalProfit).toLocaleString()} DA
            </Typography>
          </TableCell>
        </TableRow>
      </>
    );

    if (this.state.accessM.includes('SaleReport')) {
      return (
        <Box p={2}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography variant="subtitle1">N°</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Nom de Plat</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Image</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Total des Ventes</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Prix de Vente</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Cout</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Le Profit</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.saleData.length ? salesRow : noReport}
                {this.state.saleData.length ? salesTotal : <></>}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );
    } else {
      return(
      <>
        <Page404 />
      </>
      );
    }  
  }
}

export default withStyles(useStyles)(SaleReport);
