import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});
class Stock extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      userId: 0,
	      username: '',
	      pw: '',
	      open: false,
	      op:false
	    };
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.handleChange = this.handleChange.bind(this);
	    this.onChangeStatus = this.onChangeStatus.bind(this);
	    this.handleClose = this.handleClose.bind(this);
	  }


	handleClose(event) {
    if (this.state.op === true) {
      return;
    }
    this.setState({op:false});
  };

	onChangeStatus(status, userId) {
	 	this.props.onChangeStatus(true, this.state.userId);
	}

	handleSubmit(event) {
	    event.preventDefault();
	    this.loginCheck(event.target);
	 }
	Copyright() {
	  return (
	    <Typography variant="body2" color="textSecondary" align="center">
	      {'Copyright © '}
	      <Link color="inherit" href="#">
	        Your Website
	      </Link>{' '}
	      {new Date().getFullYear()}
	      {'.'}
	    </Typography>
	  );
	}

	loginCheck(event) {
	    let url = '';
	    let reqType = {};

	      url = 'https://cheztony.smartvision.dz/api/usersLogin';
	      reqType = {
	          method: 'POST',
	          headers: { 'Content-Type': 'application/json' },
	          body: JSON.stringify({ username: this.state.username, pw: this.state.pw })
	      };

	    fetch(url, reqType)
	      .then(response => response.json())
	      .then(data => {
	      	if (data!=='undefined' && data!==null) {
	      		this.props.onChangeStatus(true,data.userId);
	      		window.location.href = '/';
	      		// this.setState({userId: data.userId});
	      	}
	      })
	      .catch(error => {
	      	this.setState({open: true});
	        console.error(error);
	      });
	  }
	  handleChange(event) {
	    this.setState({[event.target.id]: event.target.value});
	  }

    render() {
    	const { classes } = this.props;
    	const action = (
	    <React.Fragment>
	      <Button color="secondary" size="small" onClick={this.handleClose}>
	        OK
	      </Button>
	      <IconButton
	        size="small"
	        aria-label="close"
	        color="inherit"
	        onClick={this.handleClose}
	      >
	        <CloseIcon fontSize="small" />
	      </IconButton>
	    </React.Fragment>
	  );
    return (
      <Grid item xs={6} sm>
      	<Container component="main" maxWidth="xs">
	      <CssBaseline />
	      <div className={classes.paper}>
	        <Avatar className={classes.avatar}>
	          <LockOutlinedIcon />
	        </Avatar>
	        <Typography component="h1" variant="h5">
	          Log in
	        </Typography>
	        <form className={classes.form} onSubmit={this.handleSubmit}>
	        <FormControl className={classes.form}>
	          <TextField
	          	onChange={this.handleChange}
	            variant="outlined"
	            margin="normal"
	            required
	            fullWidth
	            id="username"
	            label="Nom d'utilisateur"
	            name="username"
	            autoComplete="username"
	            autoFocus
	          />
	          <TextField
	          	onChange={this.handleChange}
	            variant="outlined"
	            margin="normal"
	            required
	            fullWidth
	            name="pw"
	            label="Mot de passe"
	            type="password"
	            id="pw"
	            autoComplete="current-password"
	          />
	          <FormControlLabel
	            control={<Checkbox value="remember" color="primary" />}
	            label="Remember me"
	          />
	          <Button
	            type="submit"
	            fullWidth
	            variant="contained"
	            color="primary"
	            className={classes.submit}
	          >
	            Sign In
	          </Button> 
	          </FormControl> 
	        </form>	        
	      </div>
	        <Snackbar open={this.state.open} action={action} message="error occurred!"/>
    	</Container>         
      </Grid>
    );
  }
}

export default withStyles(useStyles)(Stock);