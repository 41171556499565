import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuList from './MenuList';
import OrderBill from './OrderBill';

import { withStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  gridList: {
    flexWrap: 'nowrap'
  },
  button: {
  padding:" 10px 20px",
  textAlign: "center",
  textdecoration: "none",
  display: "inline-block",
  fontSize: "18px",
  margin: "6px 4px",
  width: "150px",
  hight: "100px"
}

});

class ViewNewOrder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tableId: 0,
      orders: {},
      orderedItems: [],
      categories: [],
      prevOrder: false,
      taxRate: 7, 
      categoryId: 0, 
      bgColor: '', 
      accessM: []
    };
    this.clearOrderItems = this.clearOrderItems.bind(this);
    this.addItemToOrder = this.addItemToOrder.bind(this);
    this.updateItemQty = this.updateItemQty.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.getMethods = this.getMethods.bind(this);
  }

  getMethods(id) {
    fetch(`https://cheztony.smartvision.dz/api/methods/${id}`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          methods: data
        });
        let access = []
        for (let i=0; i < data.length; i++) {
             access.push(data[i].component);
          } 
          this.setState({
            accessM: access
          });
      })
      .catch(error => {
        console.error(error);
      });
  }

  clearOrderItems() {
    this.setState({ orders: {} });
    this.props.history.push('/');
  }

  addItemToOrder(item) {
    if (this.state.orders[item.itemId]) {
      const newObj = { ...this.state.orders };
      newObj[item.itemId].quantity = this.state.orders[item.itemId].quantity + 1;
      this.setState({ orders: newObj });

    } else {
      const addItem = {};
      addItem[item.itemId] = item;
      addItem[item.itemId].quantity = 1;
      const obj = { ...this.state.orders, ...addItem };
      this.setState({ orders: obj });
    }
  }

  componentDidMount() {
    const tableId = this.props.match.params.tableId;
    this.getOrderedItems(tableId);
    // Rania 31/08/2021
    this.getCategory();
    this.setTableId(tableId);
    this.getMethods(localStorage.getItem('id'));
  }

  setTableId(id) {
    this.setState({ tableId: id });
  }

  // getOrderedItems(id) {
  //   fetch(`https://cheztony.smartvision.dz/api/orders/${id}`)
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.orderId) {
  //         this.setState({
  //           orderedItems: data,
  //           prevOrder: true
  //         });
  //       }
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });
  // }

  // Add By Amina 
  getOrderedItems(id) {
    fetch(`https://cheztony.smartvision.dz/api/orders/${id}`)
      .then(response => response.json())
      .then(data => {
        if (data.orderId) {
          const addItem = {};
          let obj;
          for (const item in data.items) {
            addItem[data.items[item].itemId] = data.items[item];
            obj = { ...this.state.orders, ...addItem };
          }
          this.setState({
            orders: obj,
            orderedItems: data,
            prevOrder: true
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }
  // END

  updateItemQty(itemId, qty) {
    const orders = this.state.orders;

    for (const item in orders) {
      if (parseInt(itemId) === orders[item].itemId) {
        if (qty === 1) {
          this.addItemToOrder(orders[item]);
        } else {
          const newObj = { ...this.state.orders };
          newObj[item].quantity = this.state.orders[item].quantity - 1;

          if (newObj[item].quantity <= 0) {
            delete newObj[item];
          }
          this.setState({ orders: newObj });
        }
        break;
      }
    }
  }

  // add by Rania 25/08/2021

  getCategory() {
    fetch('https://cheztony.smartvision.dz/api/category')
      .then(response => response.json())
      .then(data => {
        this.setState({
          categories: data
        });
      })
      .catch(error => {
        console.error(error);
      });
  }
  reloadCategories() {
    this.getCategory();
  }

  // Add by Rania 01/09/2021

  handleEdit(event) {
    const id = event.currentTarget.id;
    if (id === undefined || id < 0) return;
    // console.log('categoryId from handleEdit : '+this.state.categoryId);

    this.setState({
      categoryId: id
    });
    this.setState({
      bgColor: "contained"
    });
  }

  render() {
    const { classes } = this.props;
    const catlist = this.state.categories.map(menu => {
      return (
        <Button className={classes.button} onClick={this.handleEdit} id={menu.categoryId} variant={(menu.categoryId==this.state.categoryId)? "outlined":"contained"}  size="large" color="primary" >
          {menu.namecat}
        </Button>
      );
    }); 
    // console.log('categoryId from view order list: '+this.state.categoryId);
    if (this.state.accessM.includes('ViewNewOrder')) {
        return (
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} align="center">
                
          </Grid>
          <Grid item xs={12} md={2}>
            <Box pt={2} pl={1} pr={1}>
             <Button onClick={this.handleEdit} className={classes.button} id={0} variant={(this.state.categoryId==0)? "outlined":"contained"} size="large" color="primary">
                afficher tous
              </Button>
                {catlist}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box pt={2} pl={1} pr={1}>
              <MenuList categoryId={this.state.categoryId} addToOrder={this.addItemToOrder} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box pt={2}>
              <OrderBill
                table={this.state.tableId}
                orderItem={this.state.orders}
                prevOrder={this.state.prevOrder}
                orderedItems={this.state.orderedItems}
                cancelOrder={this.clearOrderItems}
                updateItem={this.updateItemQty}
                taxRate={this.state.taxRate}
              />
            </Box>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} align="center">
                
          </Grid>
          <Grid item xs={12} md={2}>
            <Box pt={2} pl={1} pr={1}>
             <Button onClick={this.handleEdit} className={classes.button} id={0} variant={(this.state.categoryId==0)? "outlined":"contained"} size="large" color="primary">
                afficher tous
              </Button>
                {catlist}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box pt={2} pl={1} pr={1}>
              <MenuList categoryId={this.state.categoryId} addToOrder={this.addItemToOrder} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box pt={2}>
              
            </Box>
          </Grid>
        </Grid>
      );
    } 
  }
}

export default withStyles(useStyles)(ViewNewOrder);