import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import LowPriority from '@material-ui/icons/LowPriority';
import { withStyles } from '@material-ui/core/styles';
import Page404 from './Page404';


const useStyles = theme => ({
  image: {
    width: theme.spacing(12),
    height: theme.spacing(8)
  },
  cell: {
    padding: theme.spacing(1.3)
  },
  button: {
  	padding: theme.spacing(1),
  	float: 'right'
  }
});

class AccountManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    	users: [],
    	userId: localStorage.getItem('id'),
      accessM: []
     };
    // this.getMethods = this.getMethods.bind(this);
    this.handleEditCredentials = this.handleEditCredentials.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  getMethods(id) {
    fetch(`https://cheztony.smartvision.dz/api/methods/${id}`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          methods: data
        });
        let access = []
        for (let i=0; i < data.length; i++) {
             access.push(data[i].component);
          } 
          this.setState({
            accessM: access
          });
        console.log('methods: ',this.state.accessM,this.state.isLoad);
      })
      .catch(error => {
        console.error(error);
      });
  }

  handleEditCredentials(event) {
	const id = event.currentTarget.id;
	this.setState({
      userId: id
    });
    window.location.href = `#/credentials/${id}/`;
  }

  handleEdit(event) {
	const id = event.currentTarget.id;
	this.setState({
      userId: id
    });
    window.location.href = `#/update-user/${id}`;
  }

  handleCilck(e) {
  	window.location.href = "#/add-user";
  }

  componentDidMount() {
    fetch('https://cheztony.smartvision.dz/api/users')
      .then(response => response.json())
      .then(data => {
        this.setState({
          users: data
        });
      })
      .catch(error => {
        console.error(error);
      });
      this.getMethods(localStorage.getItem('id'));
  }

  render() {
    const { classes } = this.props;
    console.log(this.state.userId)
    const usersRow = this.state.users.map((item, index) => {
      return (
        <TableRow key={item['UserId']}>
          <TableCell align="center">{index + 1}</TableCell>
          <TableCell align="center">{item['Username']}</TableCell>
          <TableCell align="center" className={classes.cell}>
            <Box display="flex" justifyContent="center" bgcolor="background.paper">
              <Avatar
                variant="rounded"
                alt={item['Username']}
                className={classes.image}
                src={'https://cheztony.smartvision.dz/'+item.Photo}
              />
            </Box>
          </TableCell>
          <TableCell align="center">{item['Email']}</TableCell>
          <TableCell align="center">{item.Nom}</TableCell>
          <TableCell align="center">
            {item['NÂ°TÃ©lÃ©phone']}
          </TableCell>
          <TableCell align="center">{item['DerniÃ¨reConnexion']}</TableCell>
          <TableCell align="center">
          <Box
            display="flex"
            justifyContent="center"
            bgcolor="background.paper"
          >
            {this.state.accessM.includes('UpdateUser') ? <IconButton onClick={this.handleEdit} id={item['UserId']}>
              <EditOutlinedIcon className={classes.icon} />
            </IconButton> : '' }
            
            {this.state.accessM.includes('Credentials') ? <IconButton onClick={this.handleEditCredentials} id={item['UserId']}>
              <LowPriority className={classes.icon} />
            </IconButton> : ''
            }
          </Box>
        </TableCell>
        </TableRow>
      );
    });
    if (this.state.accessM.includes('AccountManagement')) {
      return (  
        <Box p={8}>
        {this.state.accessM.includes('AddUser') ? <Button variant="contained" color="primary" className={classes.button} onClick={this.handleCilck}> 
            Ajouter utilisateur
          </Button> : ''}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Id</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Nom d'utilisateur</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Photo</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Email</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Nom</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">N° Téléphone</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Dernière Connexion</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Opération</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { usersRow }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );
    } else {
      return(
        <Box p={8}>
          <Page404 />
        </Box>
      );
    } 
  }
}

export default withStyles(useStyles)(AccountManagement);
