import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddToPhotos from '@material-ui/icons/AddToPhotos';
import Box from '@material-ui/core/Box';

export default class EmporterAddButton extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {
      accessM: []
    };
    this.handleClick = this.handleClick.bind(this);
    this.getMethods = this.getMethods.bind(this);
  }

  getMethods(id) {
    fetch(`https://cheztony.smartvision.dz/api/methods/${id}`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          methods: data
        });
        let access = []
        for (let i=0; i < data.length; i++) {
             access.push(data[i].component);
          } 
          this.setState({
            accessM: access
          });
        console.log('methods: ',this.state.accessM,this.state.isLoad);
      })
      .catch(error => {
        console.error(error);
      });
  }

  componentDidMount() {
    this.getMethods(localStorage.getItem('id'));
  }

  handleClick(e) {
      this.props.history.push(`/orderEmporter/`);
      return;
  }

  render() {
    let buttonId;
    if (this.state.accessM.includes('ViewNewOrderEmporter')) {

    } else {

    }
    return (
      <>
        {this.state.accessM.includes('ViewNewOrderEmporter') ?  
        <Box>
            <Grid style={{textAlign: 'right'}}>
                <Button
                        variant="contained"
                        size="medium"
                        // className={classes.checkId}
                        startIcon={<AddToPhotos />}
                        style={{
                          backgroundColor: '#1DBE94',
                          color: 'white',
                          display:'inline-block',
                          marginTop: '20px'
                        }}
                        onClick={this.handleClick}
                        id={buttonId}
                      >
                        Commande
                </Button>
            </Grid>
            <Grid 
            ml={0}
            style={{
                textAlign: 'center',
                paddingRight: '0px',
                paddingLeft: '0px'
              }}
            >
            <img src={'./logo192.png'} alt={'img'} style={{width:'40%'}}/>
          </Grid>
        </Box>

        : ''}
      </>
    );
  }
}

