import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AccountCircle from '@material-ui/icons/AccountCircle';
// import FormControl from '@material-ui/core/FormControl';
import Page404 from './Page404';

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  uploadBtn: {
     padding: theme.spacing(1.5)
  },
  upload: {
    display: 'none'
  },
});

class UpdateUser extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        username: '',
        fullname: '',
        email: '',
        phone: '',
        photo:'',
        new_pw: '',
        old_pw: '',
        methodId:'',
        user: [],
        formData:[],
        userId: this.props.match.params.userId,
        accessM: []
      };
      this.getMethods = this.getMethods.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
    }

  getMethods(id) {
    fetch(`https://cheztony.smartvision.dz/api/methods/${id}`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          methods: data
        });
        let access = []
        for (let i=0; i < data.length; i++) {
             access.push(data[i].component);
          } 
          this.setState({
            accessM: access
          });
        console.log('methods: ',this.state.accessM,this.state.isLoad);
      })
      .catch(error => {
        console.error(error);
      });
  }


  componentDidMount() {
    this.getUser(this.props.match.params.userId);
    this.getMethods(localStorage.getItem('id'));
  }

  getUser(id) {
      fetch(`https://cheztony.smartvision.dz/api/users/${id}`)
        .then(response => response.json())
        .then(data => {
          console.log(data.fullname,'df');
          this.setState({
            user: data,
            username: data.username,
            fullname: data.fullname,
            email: data.email,
            phone: data.phone,
            photo: data.photo
          });
        })
        .catch(error => {
          console.error(error);
        });
    }
       
  handleSubmit(event) {
      event.preventDefault();
      this.update(event.target);
    }
  Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="#">
          Your Website
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  update(event, id) {
      let url = '';
      let reqType = {};

      const formData = new FormData();
      const obj = {
        username: this.state.username, 
        email: this.state.email, 
        old_pw: this.state.old_pw, 
        new_pw: this.state.new_pw, 
        fullname: this.state.fullname, 
        phone: this.state.phone,
        userId: this.state.userId
      };
      formData.append("bb", JSON.stringify(obj));
    if (this.state.photo) {

        formData.append('image', event.photo.files[0]);
      } else {
        formData.append('image', null);
      }

        url = `https://cheztony.smartvision.dz/api/usersUpdate/${this.props.match.params.userId}`;
        reqType = {
            method: 'PUT',
            // headers: { 'Content-Type': 'application/json' },
            body: formData
        };
        fetch(url, reqType)
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            this.props.history.push("/account-management");
          } else {
            alert("Il ya une erreur!")
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
    handleChange(event) {
      this.setState({[event.target.id]: event.target.value});
    }

    render() {
      console.log(this.state.fullname,'hola');
      const { classes } = this.props;
      console.log("dlshf", this.state.accessM.includes('UpdateUser'), this.props.match.params.userId);
      if (this.state.accessM.includes('UpdateUser')) {
        return (
          <Grid item xs={6} sm>
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <AccountCircle />
              </Avatar>
              <form className={classes.form} onSubmit={this.handleSubmit}>
                <TextField
                  onChange={this.handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={this.state.username}
                  id="username"
                  label="Nom d'utilisateur"
                  name="username"
                  autoComplete="username"
                  autoFocus
                />
                <TextField
                  onChange={this.handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="fullname"
                  value={this.state.fullname}
                  label="Nom"
                  type="text"
                  id="fullname"
                />
                <TextField
                  onChange={this.handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={this.state.email}
                  name="email"
                  label="email"
                  type="Email"
                  id="email"
                />
                <TextField
                  onChange={this.handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  value={this.state.phone}
                  fullWidth
                  name="phone"
                  label="N° Téléphone"
                  type="tel"
                  id="phone"
                />
                <TextField
                  onChange={this.handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="old_pw"
                  label="Ancien Mot de passe"
                  type="password"
                  id="old_pw"
                  autoComplete="current-password"
                />
                <TextField
                  onChange={this.handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="new_pw"
                  label="Nouveau Mot de passe"
                  type="password"
                  id="new_pw"
                  autoComplete="current-password"
                />

                <Box mt={1}>
                  <input
                    accept="photo/*"
                    className={classes.upload}
                    id="photo"
                    name="photo"
                    type="file"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="photo">
                    <Button
                      onChange={this.handleChange}
                      variant="outlined"
                      color="default"
                      component="span"
                      size="large"
                      fullWidth
                      className={classes.uploadBtn}
                    >
                      Téléchargement d'image de profil 
                    </Button>
                    <Typography
                      variant="caption"
                      display="block"
                      className={classes.imageName}
                    >
                      {this.state.photo}
                    </Typography>
                  </label>
                </Box>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Valider
                </Button>
              </form>
            </div>
            </Container>         
          </Grid>
        );
      } else {
        return(
          <Grid item xs={6} sm>
            <Page404 />
          </Grid>
        );
      }
      
  }
}

export default withStyles(useStyles)(UpdateUser);