import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';


const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});
class Page404 extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      userId: 0,
	      open: false,
	      op:false
	    };    
	  }
	componentDidMount() {
		setTimeout(() => this.setState({open: true}), 3000);
		// this.setState({open: true});
  	} 
	Copyright() {
	  return (
	    <Typography variant="body2" color="textSecondary" align="center">
	      {'Copyright © '}
	      <Link color="inherit" href="#">
	        Your Website
	      </Link>{' '}
	      {new Date().getFullYear()}
	      {'.'}
	    </Typography>
	  );
	}

    render() {
    	const { classes } = this.props;
    	if (this.state.open) {
    	 return (
	      <Grid item xs={6} sm>
	      	<Container component="main" maxWidth="xs">
		      <CssBaseline />
		      <div className={classes.paper}>
		        <Typography color="secondary" component="h1" variant="h1">
		          Error 404
		        </Typography>
		        <Typography component="h1" variant="h5">
		          What you are Looking for...!!
		        </Typography>
		      </div>
	    	</Container>         
	      </Grid>
	    );
    	} else {
    		return(
	      		<Grid item xs={6} sm />

    		);
    	}
	    
  	}
}

export default withStyles(useStyles)(Page404);