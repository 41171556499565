import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';




const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  uploadBtn: {
     padding: theme.spacing(1.5)
  },
  upload: {
    display: 'none'
  },
});

class WaitList extends React.Component {
  constructor(props) {
      super(props);
      this.state = {

      };
    }





    render() {
      const { classes } = this.props;
      
      return (
        <Grid item xs={6} sm>
          <Container component="main" maxWidth="xs">
            
          </Container>         
        </Grid>
      );
    }
}

export default withStyles(useStyles)(WaitList);