import React from 'react';
import Grid from '@material-ui/core/Grid';
import MenuCustomizerTable from './MenuCustomizerTable';
import MenuCustomizerForm from './MenuCustomizerForm';
import Page404 from './Page404';

export default class MenuCustomizer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      formStatusEdit: false,
      formEditItem: {},
      accessM: []
    };
    this.reloadMenus = this.reloadMenus.bind(this);
    this.editItem = this.editItem.bind(this);
    this.getMethods = this.getMethods.bind(this);
    this.clearEditItem = this.clearEditItem.bind(this);
  }

  componentDidMount() {
    this.getMethods(localStorage.getItem('id'));
    this.getMenus();
  }

  getMethods(id) {
    fetch(`https://cheztony.smartvision.dz/api/methods/${id}`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          methods: data
        });
        let access = []
        for (let i=0; i < data.length; i++) {
             access.push(data[i].component);
          } 
          this.setState({
            accessM: access
          });
        console.log('methods: ',this.state.accessM,this.state.isLoad);
      })
      .catch(error => {
        console.error(error);
      });
  }

  getMenus() {
    fetch('https://cheztony.smartvision.dz/api/menus')
      .then(response => response.json())
      .then(data => {
        this.setState({
          menus: data,
          formStatusEdit: false,
          formEditItem: {}
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  reloadMenus() {
    this.getMenus();
  }

  editItem(itemId) {
    for (const item of this.state.menus) {
      if (item.itemId === parseInt(itemId)) {
        const editItem = { ...item };
        // editItem.imageUrl = editItem.imageUrl;
        this.setState({
          formStatusEdit: true,
          formEditItem: editItem
        });
        break;
      }
    }
  }

  clearEditItem() {
    this.setState({ formStatusEdit: false, formEditItem: {} });
  }

  render() {
    if (this.state.accessM.includes('MenuCustomizer')) {
      return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <MenuCustomizerTable
                menuList={this.state.menus}
                reloadMenus={this.reloadMenus}
                editItem={this.editItem}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MenuCustomizerForm
                reloadMenus={this.reloadMenus}
                editView={this.state.formStatusEdit}
                editItem={this.state.formEditItem}
                resetItem={this.clearEditItem}
              />
            </Grid>
          </Grid>
        );
    } else {
      return (
        <Grid container spacing={2}>
          <Page404 />
        </Grid>
      );
    }
   
  }
}
