import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import 'antd/dist/antd.css';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import socketio from "socket.io-client";
import './invoice-v1.css';

import ReactToPrint from "react-to-print";


const useStyles = theme => ({
    button: {
      minWidth: 80,
      padding: theme.spacing(1),
      margin: theme.spacing(1, 2)
    }
});

const socket = socketio.connect('http://192.168.1.35:8080');

class RestaurantPrint extends React.Component {
    
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      openChecks: [],
      invoice_t: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendToServer = this.sendToServer.bind(this);
    this.parseTime = this.parseTime.bind(this);

  }

  sendToServer(data) {
    console.log('socket.emit');
    socket.emit('msg',this.state.invoice_t)
  }

  componentDidMount() {
    fetch(`https://cheztony.smartvision.dz/api/checks/${this.props.match.params.checkId}`)
    .then(res => res.json())
    .then(data => {
      const total = this.getTotal(data);
      this.setState({ totalBill: total, total: total, cash: 0, card: total });
    })
    .catch(() => console.error('server response error'));

    // ******
    this.mounted = true;
    this.getData();
    this.unlisten = this.props.history.listen((location, action) => {
      this.getData();
    });
  }

  handleSubmit(event) {
    const { tableId } = this.props.match.params;
    let p = tableId!== undefined ? '/' : '/view-emporter'
    // var content = document.getElementById("divcontents");
    // var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    event.preventDefault();
    fetch(`https://cheztony.smartvision.dz/api/checks/${this.props.match.params.checkId}`, {
      method: 'PUT'
    })
      .then(res => res.json()) 
      // .then(pri.document.open())
      // .then(pri.document.write(content.innerHTML))
      // .then(pri.document.close())
      // .then(pri.focus())
      // .then(pri.print())
      .then(reponse => this.props.history.push(p))
      // .then(window.location.href = tableId!== undefined ? '#/' : '#/view-emporter')
      .catch(() => console.error('error updating pay'));
  }
  getTotal(data) {
    let reduceStuff = 0;
    reduceStuff = data.reduce((accumulator, item) => {
      return accumulator + parseFloat(item.salePrice) * (item.quantity);
    }, 0);
    const invoiceTotal =  reduceStuff;
    return parseFloat(invoiceTotal).toFixed(2);
  }

  parseTime(time) {
    const jsTime = new Date(time);
    let hours = jsTime.getHours();
    let minutes = jsTime.getMinutes();
    let day =jsTime.getDate();
    let mm =jsTime.getMonth()+1;
    let yyy =jsTime.getFullYear();
    day = day <10 ? "0"+day : day;
    mm = mm <10 ? "0"+mm : mm;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const timeString = `${day}/${mm}/${yyy} ${hours}:${minutes}`;
    return  timeString;
  }

  getData() {
    const checkId = this.props.match.params.checkId;
    fetch(`https://cheztony.smartvision.dz/api/checks/${checkId}`)
      .then(res => res.json())
      .then(data => {
        if (this.mounted) {
          this.setState({ openChecks: data, checkId: checkId});
        } 
      })
      .catch(() => console.error('server response error'));
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unlisten();
  }

  render() {
    const { classes } = this.props;
    const { params } = this.props.match;
    const { length } = this.state.openChecks;
    const createdAt = length ? this.state.openChecks[0].createdAt : 0;

    



    const item  = this.state.openChecks;
    const orderItemRows = item.map((orderItems, index) => {
    const { quantity, item, salePrice} = orderItems;
      return (
        <TableRow key={index}>
          <TableCell align="left">{quantity}&nbsp; &nbsp; &nbsp; </TableCell>
          <TableCell align="left"> {item}&nbsp; &nbsp; &nbsp; &nbsp; </TableCell>
          <TableCell align="left"> {salePrice}&nbsp; &nbsp; &nbsp;&nbsp; </TableCell>
          <TableCell align="left"> {(quantity*salePrice).toFixed(2)}</TableCell>
        </TableRow>
      );
    });
    const orderItemRows2 = item.map((orderItems, index) => {
    const { quantity, item, salePrice} = orderItems;
      return (
        `          ${quantity.toString().padEnd(2, ' ')}    ${item.substr(0, 9).padEnd(10, ' ')}     ${salePrice.toString().padEnd(4, ' ')}   ${(quantity*salePrice)}\n`
      );
    });
    console.log(orderItemRows2.toString().replaceAll(',',''))
    
       const i = `
                  PIZZERIA TONY
          Adress : 06004 Kherrata, Algeria
                Tel : 0770 73 09 02
     ******************************************
          Date: `+this.parseTime(createdAt)+`   Table : `+params.tableId+`

     ******************************************
          Qte   Article        Prix   Total\n`+orderItemRows2.toString().replaceAll(',','')+`
                            Total: `+this.state.totalBill+` DA

     ******************************************
                     CHEZ TONY
     ******************************************
              MERCI POUR VOTRE VISITE
     ******************************************
        Copyright SmartVision -0770 54 80 86-
`
    this.state.invoice_t = i;
 
    const checks =  (
        <div ref={el => (this.componentRef = el)}>
          <div style={{textAlign:'center', align:'center'}}> 
            <img src={'http://localhost:3000/logo192.png'} alt={'img'} 
            style={{width:'30%'}}/>
            <h4 >PIZZERIA TONY</h4>
            <div><strong>Adress : 06004 Kherrata, Algeria</strong></div>
            <div><strong>Tel : 0770 73 09 02</strong>
          </div>
                   ******************************************
        </div>
        <div gutter={24} style={{textAlign: 'center'}}>
              <div style={{paddingRight: '0%'}}>
                <strong>Date: {this.parseTime(createdAt)} &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;
                {params.tableId!==undefined ? `Table : ${params.tableId}` : ``} <br/> 
                ******************************************
                </strong>
              </div>
        </div>
            
        <Box pt={2} pr={2}>
          <TableContainer component={Paper} style={{textAlign: 'center'}}>
            <Table  style={{textAlign: 'center'}}>
            <TableHead>
                <TableRow>
                  <TableCell style={{textAlign: 'left'}}>
                    <Typography>Qte</Typography>
                  </TableCell>
                  <TableCell  style={{textAlign: 'center'}}>
                    <Typography>Article</Typography>
                  </TableCell>
                  <TableCell  style={{textAlign: 'left'}}>
                    <Typography>Prix</Typography>
                  </TableCell>
                  <TableCell  style={{textAlign: 'left'}}>
                    <Typography>Total</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderItemRows}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

           
        <div style={{textAlign: 'right', marginTop: 8}}><strong>Total :
           {this.state.totalBill}  DA </strong>&nbsp; 
        </div>
 
        <div style={{textAlign: 'center'}}>
            
              ****************************************** <br/>
          <img src={'http://localhost:3000/fcbk.png'} alt={'img'} style={{width:'3%'}}/> &nbsp;
          <img src={'http://localhost:3000/insta.png'} alt={'img'} style={{width:'3%'}}/> &nbsp;
           CHEZ TONY
        </div>
        {/* <Row  style={{textAlign: 'center'}}>
            
              ****************************************** <br/>
          Pizzeria chez Tony, une toute nouvelle pizzeria. <br/>
        </Row> */}
        {/* className="footer" ,  position: 'fixed',bottom: 0*/}
        <div  style={{textAlign: 'center'}} >
            
             ****************************************** <br/>
        <div  style={{fontFamily: 'cursive'}}>  MERCI POUR VOTRE VISITE
        </div>
             ****************************************** <br/>
            Copyright SmartVision -0770 54 80 86-
            </div>
        </div> 
    );
    return (
      <>
      <form onSubmit={this.handleSubmit} onReset={this.handleReset} name="payForm">
          <Box m={5} mt={0}>
            <ReactToPrint
              trigger={() => (
                <Button  type="submit"
                  variant="contained" color="primary" className={classes.button} >
                  PAYER
                </Button>
              )}
              content={() => this.componentRef}
            />
            <Button  onClick={this.sendToServer} 
              variant="contained" color="primary" className={classes.button} >
              IMPRIMER
            </Button>
          </Box>
        </form>
      <Box mt={10} ml={2} mb={2}>
        <Grid container spacing={1}>
          {checks}
        </Grid>
      </Box>
      </>
    );
  }
}

export default withStyles(useStyles)(RestaurantPrint);
