import React from 'react';
import ViewPayEmporter from './ViewPayEmporter';
import RestaurantPrint from './RestaurantPrint';
import ViewChecksEmporter from './ViewChecksEmporter';
import ViewCheckItemEmporter from './ViewCheckItemEmporter';
import EmporterAddButton from './EmporterAddButton';
import Grid from '@material-ui/core/Grid';
import { Switch, Route } from 'react-router-dom';
import Page404 from './Page404';
// import { textAlign } from '@material-ui/system';

export default class ViewEmporter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessM: []
    };
    this.getMethods = this.getMethods.bind(this);
  }

  getMethods(id) {
    fetch(`https://cheztony.smartvision.dz/api/methods/${id}`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          methods: data
        });
        let access = []
        for (let i=0; i < data.length; i++) {
             access.push(data[i].component);
          } 
          this.setState({
            accessM: access
          });
        console.log('methods: ',this.state.accessM);
      })
      .catch(error => {
        console.error(error);
      });
  }
  componentDidMount() {
    this.getMethods(localStorage.getItem('id'));
  }


  render() {
    let { path, url } = this.props.match;
    const { history } = this.props;
    path = path.replace(/\/$/, '');
    url = url.replace(/\/$/, '');
    if (this.state.accessM.includes('ViewEmporter')) {
      return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            {this.state.accessM.includes('ViewChecksEmporter') ? 
            <ViewChecksEmporter url={url} history={history} /> : ''}
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
          >
            <Switch>
              <Route path={`${path}/paycheckEmporter/:checkId/0`} component={ViewPayEmporter} />
              <Route path={`${path}/checkitem/:checkId/:tableId`} component={ViewCheckItemEmporter} />
            </Switch>
            <Switch>
              <Route path={`${path}/paycheckEmporter/:checkId/0`} component={RestaurantPrint} />
            </Switch>
              <Switch>
              <Route exact path={path} component={EmporterAddButton} />
            </Switch>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return(
    <>
    <Page404 />
    </>
    );
    
  }
  }
}
